import React from 'react'
import './privacy.css'

const Privacy = () => {
  return (
    <div className='privacy-policy'>
        <div className="privacy-h">
            <h1>Privacy Policy</h1>
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. </p>
            <h2>Information Gathering And Usage</h2>
                <h3>Cookies</h3>
                <p>A cookie is a small data file that Websites or App write to your hard drive when you visit them. A cookie file can contain information such as a user ID that the site uses to track the pages you have visited. A cookie can contain information you supply yourself. A cookie can't read data of your hard disk or read cookie files created by other sites. Our Website/App uses cookies to track user traffic patterns and for the personalisation feature.</p>
                <h3>User Communications</h3>
                <p>When you send email or other communications to our Website, we may retain those communications in order to process your inquiries, respond to your requests and improve our services. We may use your email address to communicate with you about our services.</p>
                <h3>Log information</h3>
                <p>When you access our Website services via a browser or other client, our servers automatically record certain information. These server logs may include information such as your web request, your interaction with a service, Internet Protocol address, browser type, browser language, the date and time of your request and one or more cookies that may uniquely identify your browser or your account.</p>
                <h3>Feedback</h3>
                <p>SK Phoenix's Feedback Form requires personal information of users like contact number, name and e-mail address and demographic information like their zip code, age etc. for any feedback by the users pertaining to the services rendered by SK Phoenix.</p>
                <h3>Further Improvement</h3>
                <p>Apart from the above, we may use user information to provide, maintain, protect, improve and develop our services.</p>
                <h3>Queries regarding the website</h3>
                <p>If you have any query regarding this Privacy Policy, you may contact at info@skphoenix.co.in.</p>
        </div>
    </div>
  )
}

export default Privacy