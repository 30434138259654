import React from 'react'
import './refund.css'

export const Refund = () => {
  return (
    <div className='refund'>
        <h1>Refund and Cancellation</h1>
        <p>
        Below mentioned are some of the important instructions that the candidate should keep in mind at the time of filling out the SPOT (Scholarship and Performance Oriented Test) Exam application form: -
        </p>
      
          <ol>
            <li>1. Before filling out the SPOT Exam application form, the candidates must carefully read the information provided while filling the details.</li>
            <li>2. Valid Mobile number must be provided as all further information regarding SPOT exam will be communicated through call.</li>
          
          <li>3. SPOT Exam application fee is generally non-refundable. However, SK Phoenix will refund the duplicate or extra application fee paid by the candidates to enroll for the examination. Once you have successfully registered for the SPOT Exam, it cannot be cancelled either you come to attempt the test or not, the payment will not be refunded.</li>
          </ol>
        </div>
  )
}
