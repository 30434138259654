import React from 'react'
import './social-contacts.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'

const Socialcontacts = () => {
  return (
    <div className='social'>
    <a href="https://www.facebook.com/skphoenixpta" target="_blank">
    <FontAwesomeIcon icon={faFacebookF} />
    </a>  
    <a href="https://www.instagram.com/invites/contact/?i=1702zdp62z4fh&utm_content=nadvf4y" target="_blank">
      <FontAwesomeIcon icon={faInstagram} />
    </a>
    </div>
  )
}

export default Socialcontacts