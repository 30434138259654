import React from 'react'
import './footer.css'
import logo from '../../assets/logo.png'
import contact from '../../assets/extra/contact.png'
import { Link } from 'react-router-dom'
import Socialcontacts from '../../common/social-contacts/Socialcontacts'

const Footer = () => {
  return (
    <div className='footer'>
        <div className="c-footer">
        <div className="f-logo">
        <img src={logo} alt="" />
        <h4>2nd Floor,<br /> Above Reliance Smart Point,<br /> Backside Columbia Asia Hospital <br /> Patiala-147001 (Pb.)</h4>
        <h3>9877058172</h3> 
        </div>
        <div className="f-about">
            <h1>About</h1>
            <Link to="vision">Our Vision</Link>
            <Link to="mission">Our Mission</Link>
        </div>
        <div className="f-results">
            <h1>Our Results</h1>
            <Link to="boards">Boards</Link>
            <Link to="jee">JEE</Link>
            <Link to="neet">NEET</Link>
        </div>
        <div className="f-courses">
            <h1>Our Courses</h1>
            <Link to="junior">Junior</Link>
            <Link to="senior">Senior</Link>
            <Link to="commerce">Commerce</Link>
        </div>
        <div className="more">
            <h1>Admission Test</h1>
            <Link to="spot">SPOT</Link>
            <Link to="samplepapers">Sample Papers</Link>
            <Socialcontacts/>
        </div>
        </div>
        <div className="line"></div>
        <div className="f-privacy">
        <div className='privacy'><a href="privacy">Privacy Policy</a></div>
        <div className="terms"><a href="terms">Terms & Conditions</a></div>
        <div className="f-refund">
            <a href="refund">Refund and Cancellation</a>
        </div>
        </div>
        <div className="line"></div>
        <div className='d-footer'>
        <div className='copyright'><span><b>&copy;2022, SK PHOENIX</b> All Rights Reserved</span></div>
        <div className="designer">Designed by:- <a href="https://www.instagram.com/codingsmoke/">@CodingSmoke</a></div>
        </div>
    </div>
  )
}

export default Footer
