import React from 'react'
import './results.css'
import jashan from '../../assets/jashan.png'
import prabh from '../../assets/prabhdeep.png'
import karan from '../../assets/karansingh.png'
import sahil from '../../assets/sahil.png'
import ranjit from '../../assets/ranjit.png'
import pratham from '../../assets/pratham.png'
import sahilchopra from '../../assets/sahilchopra.png'
import saksham from '../../assets/saksham.png'
import veer from '../../assets/veer.png'
import yuvraj from '../../assets/yuvraj.png'
import lakhwinder from '../../assets/lakhwinder.png'
import hareshpreet from '../../assets/hareshpreet.png'

const Results = () => {
  return (
    <div data-aos="fade-up" className='results'>
        <h1>Boards Results</h1>
        <div className="r-content">
        <div className="heading">
        <h2>12th Boards</h2>
        </div>
        <div className="recs">
          <div className="cont">
          <img src={hareshpreet} alt="hareshpreet" />
              <span>Hareshpreet Kaur</span>
              <span><b> Patiala Distt. Topper</b></span>
              <span><b>98.8%</b></span>
          </div>
            <div className="cont">
              <img src={jashan} alt="jashan" />
              <span>Jashanpreet Singh</span>
              <span><b>95.2%</b></span>
            </div>
            <div className="cont">
            <img src={prabh} alt="prabh" />
              <span>Prabhdeep Kaur</span>
              <span><b>95%</b></span>
            </div>
            <div className="cont">
            <img src={karan} alt="karan" />
              <span>Karan Singh Mann</span>
              <span><b>90%</b></span>
            </div>
            <div className="cont">
            <img src={sahil} alt="sahil" />
              <span>Sahil Khan</span>
              <span><b>89%</b></span>
            </div>
            <div className="cont">
            <img src={ranjit} alt="ranjit" />
              <span>Ranjit Singh</span>
              <span><b>89%</b></span>
            </div>
            <div className="cont">
            <img src={pratham} alt="pratham" />
              <span>Pratham Chauhan</span>
              <span><b>88%</b></span>
            </div>
            </div>
          </div>

          <div className="r-content">
          <div className="heading">
        <h2>10th Boards</h2>
        </div>
        <div className="rec">
          <div className="cont">
              <img src={sahilchopra} alt="" />
              <span>Sahil Chopra</span>
              <span>Patiala Distt. Topper</span>
              <span><b>98.8%</b></span>
          </div>
          <div className="cont">
              <img src={saksham} alt="" />
              <span>Saksham</span>
              <span><b>90%</b></span>
          </div>
          <div className="cont">
              <img src={veer} alt="" />
              <span>Veer Deepinder</span>
              <span><b>84%</b></span>
          </div>
          <div className="cont">
              <img src={yuvraj} alt="" />
              <span>Yuvraj Singh</span>
              <span><b>83%</b></span>
          </div>
          <div className="cont">
              <img src={lakhwinder} alt="" />
              <span>Lakhwinder Singh</span>
              <span><b>76%</b></span>
          </div>
          </div>
          </div>
          </div>
  )
}

export default Results