import React from 'react'
import './spot.css'
import image from '../../assets/spotbanner.jpg'
import {Link} from 'react-router-dom'

const Spot = () => {

  return (
    <div data-aos="fade-up" className='spot'>
        <div className="s-left">
        <img src={image} alt="" />
        </div>

        <div className="s-right">
            <div className="spot-desc">
                <h1>SPOT (Scholarship & Performance Oriented Test)</h1>
                <h2>Test Date: 20th October 2024</h2>
                <h2>Venue: Patiala, Nabha, Samana</h2>
                <h1>For Online Registration</h1>
                <h1>⬇Click on Enroll Now⬇</h1>
            </div>
        <div className="s-links">
        <a href="https://rzp.io/l/Spot-Registration">
            <div className="s-btn">Enroll</div>
            <div className="s-btn1">Now</div>
        </a>

        <Link to='/samplepapers'>Click Here for Sample papers</Link>
        </div>
        </div>

    </div>
  )
}

export default Spot


