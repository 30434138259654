import React from 'react'
import './neet.css'
import krishan from '../../assets/krishan.jpg'
import kanishka from '../../assets/kanishka.jpg'
import chetan from '../../assets/chetan.jpg'

const Neet = () => {
  return (
    <div className='neet'>
      <h1>NEET Results</h1>
      <div className="n-content">
        <div className="n-heading">
        <h2>NEET</h2>
        </div>
        <div className="n-recs">
          <div className="n-cont">
          <img src={chetan} alt="" />
              <span>Chetan</span>
              <span>NEET 2022</span>
              <span><b>650/720</b></span>
          </div>
          <div className="n-cont">
          <img src={krishan} alt="" />
              <span>Krishan Kumar</span>
              <span>NEET 2022</span>
              <span><b>618/720</b></span>
          </div>
          <div className="n-cont">
          <img src={kanishka} alt="" />
              <span>Kanishka</span>
              <span>NEET 2021</span>
              <span><b>Rank 177</b></span>
          </div>
          </div>
    </div>
    </div>
  )
}

export default Neet