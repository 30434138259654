import React from 'react'
import './junior.css'
import juniorimg from '../../assets/onlinestudy.png'

const Junior = () => {
  return (
    <div data-aos="fade-up" className='junior'>
        <div className="j-heading"> <h1>Classroom Program (Junior Section)</h1> </div>
        <div className="j-content">
        <div className='st-img'>
            <img src={juniorimg} alt="" />
        </div>
        <div className='st-junior'>
            <div className='s-junior'>
            <h2>Class VIII (STARTER BATCH)</h2>
            <ul>
                <li>Course Duration 12 Months</li>
                <li>April to March</li>
                <li><b>Classes 4 Days A Week</b></li>
                <li>Subjects To Be Covered:</li>
                <span>Physics, Chemistry, Biology, Mathematics, Social Studies, Mental Ability & English</span>
            </ul>
            </div>
            <div className='s-junior'>
            <h2>Class IX (SHUBH BATCH)</h2>
            <ul>
                <li>Course Duration 12 Months</li>
                <li>April to March</li>
                <li><b>Classes 4 Days A Week</b></li>
                <li>Subjects To Be Covered:</li>
                <span><b>Physics, Chemistry, Biology, Mathematics, Social Studies, Mental Ability & English</b></span>
            </ul>
            </div>
            <div className='s-junior'>
            <h2>Class X (SAMPURN-1 BATCH) CBSE</h2>
            <ul>
                <li>Course Duration 12 Months</li>
                <li>April to January</li>
                <li><b>Classes 4 Days A Week</b></li>
                <li>Subjects To Be Covered:</li>
                <span><b>Physics, Chemistry, Biology, Mathematics, Social Studies, Mental Ability & English</b></span>
            </ul>
            </div>
            <div className='s-junior'>
            <h2>Class X (SAMPURN-2 BATCH) ICSE</h2>
            <ul>
                <li>Course Duration 12 Months</li>
                <li>April to January</li>
                <li><b>Classes 4 Days A Week</b></li>
                <li>Subjects To Be Covered:</li>
                <span><b>Physics, Chemistry, Biology, Mathematics, Social Studies, Mental Ability & English</b></span>
            </ul>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Junior