import React, { useState } from "react";
import "./navbar.css";
import logo from '../../assets/logo.png';
import { GiHamburgerMenu } from "react-icons/gi";

import { Link } from "react-router-dom";

const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  return (
    <>
      <nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
          <ul>
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/spot">SPOT</Link>
            </li>
            <li>
              <Link to="/about">ABOUT</Link>
            </li>
            <li>
            <div className="dropdown">
          <a className="dropbtn">COURSES</a>
          <div className="dropdown-content">
            <Link to="/junior">Junior</Link>
            <Link to="/senior">Senior</Link>
            <Link to="/commerce">Commerce</Link>
          </div>
          </div>
            </li>
            <li>
            <div className="dropdown">
          <a className="dropbtn">RESULTS</a>
          <div className="dropdown-content">
            <Link to="/boards">Boards</Link>
            <Link to="/jee">JEE</Link>
            <Link to="/neet">NEET</Link>
          </div>
        </div>
            </li>
          </ul>
        </div>

        <div className="social-media">
          <div className="hamburger-menu">
            <a onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;