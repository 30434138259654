import React from 'react'
import './jee.css'
import anmol from '../../assets/anmol.jpg'

const Jee = () => {
  return (
    <div className='jee'>
      <h1>JEE Results</h1>
      <div className="j-content">
        <div className="j-heading">
        <h2>JEE Mains</h2>
        </div>
        <div className="j-recs">
          <div className="j-cont">
          <img src={anmol} alt="" />
              <span>Anmol Singh</span>
              <span>JEE Main 2022</span>
              <span><b>Rank 7017</b></span>
          </div>
          </div>
    </div>
    </div>

  )
}

export default Jee