import React from 'react'
import './about.css'
import education from '../../assets/education.png'
import dimg from '../../assets/directorsmsg.JPG'

const About = () => {
  return (
    <div data-aos="fade-up" className='about'>
        <div className="about-us">
          <div className="a-heading">About Us</div>
          <div className="a-subheading">
            <div className="a-about">
            <h2>We help students to achieve their dreams.</h2>
            </div>
            <div className="a-img">
              <img src={education} alt="" />
            </div>
            </div>
        </div>
        <div className="history">
          <div className="d-msg">
            <h1>Director's Message</h1>
            <p>As the Managing Directors of SK Phoenix. We would like to take this
                opportunity to share our vision and mission. It’s due to our strong commitment to
                the quest for excellence in the field of education, that SK Phoenix is making rapid
                progress by providing better resources to impact best quality education.
                We believe in harnessing the latest technology to deliver effective outcomes.
                Thus, we continuously our teaching tools and techniques as well academic
                programs. Our technology enabled education to be a blend of cost-effective and
                simplicity learning. We make sure that every student realizes his or her potential and nurture it
                through knowledge. We strive to create a dynamic environment for education
                and set the stage for student’s achievement and success.    
            </p>
            </div>
            {/* <div className="d-img">
              <img src={dimg} alt="" />
            </div> */}
        </div>

    </div>
  )
}

export default About