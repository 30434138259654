import React from 'react'
import './papers.css'
import sampleimg from '../../assets/sampleimg.JPG'
import seven from '../../assets/papers/seven.pdf'
import eight from '../../assets/papers/eight.pdf'
import nine from '../../assets/papers/nine.pdf'
import ten from '../../assets/papers/ten.pdf'
import elevenmed from '../../assets/papers/elevenmed.pdf'
import elevennonmed from '../../assets/papers/elevennonmed.pdf'

const Papers = () => {
  return (
    <div className='papers'>
        <h1>Sample Papers</h1>
        <div className="s-papers">
      <div className="p-img">
            <img src={sampleimg} alt="" />
        </div>
        <div className='samplepapers'>
            <div className="p-rec">
            <div className="p-cont">
              <h2>7th Moving to 8th</h2>
              Sample Paper:<a href={seven} target="_blank">Starter Batch</a>
            </div>
            <div className="p-cont">
            <h2>8th Moving to 9th</h2>
            Sample Paper:<a href={eight}>Shubh Batch</a>
            </div>
            <div className="p-cont">
            <h2>9th Moving to 10th</h2>
              Sample Paper:<a href={nine}>Sampurn Batch</a>      
            </div>
            <div className="p-cont">
            <h2>10th Moving to 11th</h2>
              Sample Paper:<a href={ten}>Sashakt Batch</a>              
            </div>
            <div className="p-cont">
            <h2>11th Med Moving to 12th Med</h2>
              Sample Paper:<a href={elevenmed}>Saahas Batch Med</a>
            </div>
            <div className="p-cont">
            <h2>11th NM Moving to 12th NM</h2>
              Sample Paper:<a href={elevennonmed}>Saahas Batch NM</a>
            </div>
          </div>
        </div>
        </div>
    </div>
  )
}

export default Papers