import React from 'react'
import './commerce.css'
import commerce from '../../assets/extra/commerce.png'

const Commerce = () => {
  return (
    <div data-aos="fade-up" className='commerce'>
        <div className="c-head"> <h1>Classroom Program (Senior Section) COMMERCE</h1> </div>
        
        <div className="c-content">
        <div className='co-img'>
            <img src={commerce} alt="commerce" />
        </div>
        <div className='sen-commerce'>
        <div className='s-commerce'>
            <h2>Class XI (SAKSHAM BATCH) COMMERCE</h2>
            <ul>
                <li>Course Duration 12 Months</li>
                <li><b>Classes 4 Days A Week</b></li>
                <li>Subjects To Be Covered:</li>
                <span><b>Accountancy, Economics & Business Studies</b></span>
            </ul>
            </div>
            <div className='s-commerce'>
            <h2>Class XII (SAFAL BATCH)COMMERCE</h2>
            <ul>
                <li>Course Duration 12 Months</li>
                <li><b>Classes 4 Days A Week</b></li>
                <li>Subjects To Be Covered:</li>
                <span><b>Accountancy, Economics & Business Studies</b></span>
            </ul>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Commerce