import React from 'react'
import { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import './homepage.css'
import headerimg from '../../assets/headerphoto.png'
import harsh from '../../assets/harsh2.png'
import poster from '../../assets/poster2.jpg'
import classimg from '../../assets/extra/class.jpeg'
import attention from '../../assets/extra/attention.png'
import hostel from '../../assets/extra/hostel.png'
import motivation from '../../assets/extra/motivation.png'
import ptm from '../../assets/extra/ptm.png'
import selfstudy from '../../assets/extra/selfstudy.png'
import timemanagement from '../../assets/extra/timemanagement.png'
import transport from '../../assets/extra/transport.png'
import message from '../../assets/extra/message.png'
import i1 from '../../assets/gallery/i1.JPG'
import i2 from '../../assets/gallery/i2.JPG'
import i3 from '../../assets/gallery/i3.JPG'
import i4 from '../../assets/gallery/i4.JPG'
import i5 from '../../assets/gallery/i5.JPG'
import i6 from '../../assets/gallery/i6.jpeg'
import i7 from '../../assets/gallery/i7.jpeg'
import i8 from '../../assets/gallery/i8.jpeg'
import s15 from '../../assets/SliderData/15.PNG'
import s16 from '../../assets/SliderData/16.PNG'
import s17 from '../../assets/SliderData/17.PNG'
import s18 from '../../assets/SliderData/18.PNG'
import s19 from '../../assets/SliderData/19.PNG'
import s20 from '../../assets/SliderData/20.PNG'
import s21 from '../../assets/SliderData/21.PNG'
import s22 from '../../assets/SliderData/22.PNG'
import s23 from '../../assets/SliderData/23.PNG'
import s24 from '../../assets/SliderData/24.PNG'
import s25 from '../../assets/SliderData/25.jpeg'
import s26 from '../../assets/SliderData/26.jpeg'
import s27 from '../../assets/SliderData/27.jpeg'
import s28 from '../../assets/SliderData/28.jpeg'
import s29 from '../../assets/SliderData/29.jpeg'
import testvideo from '../../assets/testimonial2.mp4'
import { BsFillPlayFill, BsPauseFill} from 'react-icons/bs';

const Homepage = () => {

    const formRef = useRef(1);
  const [done , setDone] = useState(false);

  const Submit = (e) => {
    e.preventDefault();
    emailjs
    .sendForm(
      "service_xvejwyc",
      "template_kgucfmg",
      formRef.current,
      "JCdMDf56UtKCat2_0",
    )
    .then(
      (result)=> {
        console.log(result);
        setDone(true);
      },
      (error)=> {
        console.log(error);
      }
    );
  }

  const [playVideo, setPlayVideo] = React.useState(false);
  const vidRef = React.useRef();

  return (
    <div className='Homepage'>

    <div className="maylike-products-wrapper-two">
      <div className="marquee-two">
        <div className="maylike-products-container-two track-two">
        <img src={s15} alt="gallery" />
        <img src={s16} alt="gallery" />
        <img src={s17} alt="gallery" />
        <img src={s18} alt="gallery" />
        <img src={s19} alt="gallery" />
        <img src={s20} alt="gallery" />
        <img src={s21} alt="gallery" />
        <img src={s22} alt="gallery" />
        <img src={s23} alt="gallery" />
        <img src={s24} alt="gallery" />
        <img src={s25} alt="gallery" />
        <img src={s26} alt="gallery" />
        <img src={s27} alt="gallery" />
        <img src={s28} alt="gallery" />
        <img src={s29} alt="gallery" />
        </div>
      </div>
    </div>


    <div data-aos="fade-up" className='header'>
        <div className='h-img-left'>
            <img src={headerimg} alt="student" />
        </div>
        <div className="h-cont">
            <h1>A better <br /> learning <br /> future <br /> starts <br /> here.</h1>
        </div>
        <div className='h-img'>
            <img src={harsh} alt="student" />
        </div>
    </div>

    <div data-aos="fade-up" className='who'>
        <div className='who-content'>
            <h1>Director's Message</h1>
            <p>SK Phoenix is most trusted and result oriented institute for IIT-JEE|AIIMS|NEET|BOARDS in the area. We provide best education  for top most competitive exams. We started our journey of Phoenix in October 2020 with the aim to provide our best to the students who can do maximum in competitive level exams, our main usp is that we focus on every student individually in all the aspects. Our team is well managed and developed. The team is well highlighted by very good faculty members. We provide 3D support system to the child which includes doubts handling, class room teaching, test preparation, mentorship, thorough grooming in each subject and personal attention. We are a team of highly dedicated and committed faculties with immense experience in our fields.
            </p>
        </div>
        <div className="w-img">
            <img src={poster} alt="student" />
        </div>
    </div>

    <div data-aos="fade-up" className='whyus'>
        <div className="c-img">
            <img src={classimg} alt="" />
        </div>
        <div className='services'>
            <div className="s-heading"> <h1>Our Features</h1> </div>
            
            <div className="w-rec">
            <div className="w-cont">
                <img src={hostel} alt="" />
                <span>Hostel Facility Available</span>
            </div>
            <div className="w-cont">
                <img src={transport} alt="" />
                <span>Transport Facility Available</span>
            </div>
            <div className="w-cont">
                <img src={ptm} alt="" />
                <span>Regular Parent Teacher Meeting</span>
            </div>
            <div className="w-cont">
                <img src={timemanagement} alt="" />
                <span>Proper Time Management Techniques</span>
            </div>
            <div className="w-cont">
                <img src={motivation} alt="" />
                <span>Motivation For Better Performance</span>
            </div>
            <div className="w-cont">
                <img src={attention} alt="" />
                <span>Personal Attention to Every Student</span>
            </div>
            <div className="w-cont">
                <img src={selfstudy} alt="" />
                <span>Self Study Under Proper Invigilation</span>
            </div>
            </div>
        </div>
    </div>


    <div className="maylike-products-wrapper">
      <h2>Gallery</h2>
      <div className="marquee">
        <div className="maylike-products-container track">
        <img src={i1} alt="gallery" />
        <img src={i2} alt="gallery" />
        <img src={i3} alt="gallery" />
        <img src={i4} alt="gallery" />
        <img src={i5} alt="gallery" />
        <img src={i6} alt="gallery" />
        <img src={i7} alt="gallery" />
        <img src={i8} alt="gallery" />
        </div>
      </div>
    </div>

    <div className='testimonial'>
        <video
        ref={vidRef}
        src={testvideo}
        type="video/mp4"
        loop
        controls={false}
        />
        <div className="app_video-overlay">
        <div className="app_video-overlay_circle"
        onClick={()=> {
            setPlayVideo(!playVideo);
            if (playVideo) {
                vidRef.current.pause();
        } else {
            vidRef.current.play();
        }
        }}
        >
            {playVideo ? (
                <BsPauseFill color="#fff" fontSize={30} />
            ) : (
                <BsFillPlayFill color="#fff" fontSize={30} />
            )
        }
        </div>
        </div>
    </div>

    <div data-aos="fade-up" className='form'>
        <div className="f-left">
        <h1>Request A Call Back</h1>
        <img src={message} alt="" />
        </div>
        <div className="f-right">
        <h2>Want to know more about our Institute?</h2>
         <h2>Fill your details below and our team will contact you.</h2>
        <form className='f-form' ref={formRef} onSubmit={Submit}>
        
           <input type="text" name='user_name' placeholder='Your Name Here' />
           <input type="text" name='user_mobile' placeholder='Mobile no.' />
           <input type="text" name='user_email' placeholder='Email' />
           <textarea placeholder='Message' name='message'  id="" cols="40" rows="10"></textarea>
           <button type="submit">Submit</button>
           {done && <div>Email Sent</div>}
           </form>
        </div>
    </div>
    </div>
  )
}

export default Homepage
