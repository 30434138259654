import React from 'react'
import './vision.css'
import vision from '../../assets/extra/vision.png'

const Vision = () => {
  return (
    <div data-aos="fade-up" className='vision'>
            <h1>Our Vision</h1>
        <div className="v-rec">
        <div className="h-vision">
            <p>Our ambition is to nurture enterprising and passionate future leaders, well-rounded, in their academic and intuitive strengths with an aspiring outlook in their career.</p>
        </div>
        <div className="v-img">
            <img src={vision} alt="" />
        </div>
        </div>
    </div>
  )
}

export default Vision