import { Route, Routes } from "react-router-dom"
import './App.css';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Spot from './components/spot/Spot';
import About from './components/about/About';
import Foundation from './components/foundation/Foundation';
import Engineering from './components/engineering/Engineering';
import Results from './components/results/Results';
import Faculty from './components/faculty/Faculty';
import Junior from './components/junior/Junior';
import Senior from './components/senior/Senior';
import Vision from './components/vision/Vision';
import Mission from './components/mission/Mission';
import Commerce from './components/commerce/Commerce';
import Neet from './components/Neet/Neet';
import Jee from './components/Jee/Jee';
import Aos from "aos";
import {useEffect} from "react";
import "aos/dist/aos.css";
import Homepage from "./components/Homepage/Homepage";
import Papers from './components/Sample-Papers/Papers';
import Privacy from "./components/privacypolicy/Privacy";
import { Terms } from "./components/terms/Terms";
import { Refund } from "./components/refund/Refund";


function App() {

  useEffect(()=> {
    Aos.init();
  }, []
  );

  return (
     <>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Homepage/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/spot" element={<Spot/>} />
        <Route path="/results" element={<Results/>} />
        <Route path="/junior" element={<Junior/>} />
        <Route path="/senior" element={<Senior/>} />
        <Route path="/engineering" element={<Engineering/>} />
        <Route path="/vision" element={<Vision/>} />
        <Route path="/mission" element={<Mission/>} />
        <Route path="/samplepapers" element={<Papers/>} />
        <Route path="/spot/samplepapers" element={<Papers/>} />
        <Route path="/commerce" element={<Commerce/>} />   
        <Route path="/boards" element={<Results/>} />
        <Route path="/jee" element={<Jee/>} />
        <Route path="/neet" element={<Neet/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/refund" element={<Refund/>} />
        </Routes>
        <Footer/>
        </>
  );
}

export default App;
