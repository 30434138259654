import React from 'react'
import './senior.css'
import senior from '../../assets/seniorstudy.png'

const Senior = () => {
  return (
    <div data-aos="fade-up" className='senior'>
        <div className="sen-head"> <h1>Classroom Program (Senior Section) JEE & NEET</h1> </div>
        
        <div className="sen-content">
        <div className='sen-img'>
            <img src={senior} alt="" />
        </div>
        <div className='sen-senior'>
            <div className='s-senior'>
            <h2>Class XI (SASHAKT BATCH)</h2>
            <ul>
                <p>Two Year-Long classroom program for both JEE and NEET. Sashakt ensures that student achieve greater success in JEE Main+Advanced | NEET and other entrance exams along with XII CBSE Boards and National Level Scholastic Exam such as KVPY, NSEA/C/P/B, RMO and International Olympiads by starting right on time and with full resolve.</p>
                <li><b>Classes 5 Days A Week</b></li>
                <li>Subjects To Be Covered:</li>
            </ul>
            </div>
            <div className='s-senior'>
            <h2>Class XII (SAAHAS BATCH)</h2>
            <ul>
                <p>Year-Long classroom program for XI moving student is designed to cater to the twin demands of JEE | NEET level exhaustive preparedness and Boards level acuity. Saahas Also addresses the need for revision of the concepts from class XI for the aspirants.</p>
                <li><b>Classes: 5 Days A Week</b></li>
                <li>Tests: Alternative Sundays</li>
            </ul>
            </div>
            <div className='s-senior'>
            <h2>Class XII Pass Students (SARVOTTAM BATCH)</h2>
            <ul>
                <p>Year-Long classroom program for drop year students to define success in JEE | NEET. Sarvottam is specially designed rank improvement course to help student understand the trickiest of the concepts while ensuring exhaustive practice and continues assessment for boosting the performance.</p>
                <li><b>Classes 5 Days A Week</b></li>
                <li>Tests: Alternative Sundays</li>
            </ul>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Senior