import React from 'react'
import './engineering.css'
import engimg from '../../assets/Engineering.png'

const Engineering = () => {
  return (
    <div data-aos="fade-up" className='engineering'>
        <h1>Engineering & Medical</h1>
        <img src={engimg} alt="" />
    </div>
  )
}

export default Engineering