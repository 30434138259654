import React from 'react'
import './mission.css'
import mission from '../../assets/extra/mission.png'

const Mission = () => {
  return (
    <div data-aos="fade-up" className='mission'>
            <h1>Our Mission</h1>
        <div className="m-rec">
        <div className="h-mission">
            <p>We aim to be the preferred education partner and destination for Engineering & Medical aspirants by providing equal opportunity to every student to leverage the best resources of the country under one roof in commencing their academic career.</p>
        </div>
        <div className="m-img">
            <img src={mission} alt="" />
        </div>
        </div>
    </div>
  )
}

export default Mission